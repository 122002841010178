/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.0.1): index.umd.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import Alert from '../src/alert'
import Button from '../src/button'
import Carousel from '../src/carousel'
import Collapse from '../src/collapse'
import Dropdown from '../src/dropdown'
import Modal from '../src/modal'
import Offcanvas from '../src/offcanvas'
import Popover from '../src/popover'
import ScrollSpy from '../src/scrollspy'
import Tab from '../src/tab'
import Toast from '../src/toast'
import Tooltip from '../src/tooltip'

export default {
  Alert,
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  Offcanvas,
  Popover,
  ScrollSpy,
  Tab,
  Toast,
  Tooltip
}
